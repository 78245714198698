
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBoV3yAnyjcj7DsPHKZHtVGl2RUaykEGTY",
//   authDomain: "beaudiary-ff001.firebaseapp.com",
//   databaseURL: "https://beaudiary-ff001.firebaseio.com",
//   projectId: "beaudiary-ff001",
//   storageBucket: "beaudiary-ff001.appspot.com",
//   messagingSenderId: "620464318823",
//   appId: "1:620464318823:web:04d2fabad2ac708527e7c2",
//   measurementId: "G-YBD1ZP6HXB"
// };

const firebaseConfig = {
  apiKey: "AIzaSyDaaiw0ZCZdVHJxYIkijYQEjIlfkYuXhm0",
  authDomain: "temp-portal-514ba.firebaseapp.com",
  projectId: "temp-portal-514ba",
  storageBucket: "temp-portal-514ba.appspot.com",
  messagingSenderId: "1001434894901",
  appId: "1:1001434894901:web:ed8c9df4c4b1a4cc6f177c",
  measurementId: "G-9ZHHXGXTP8"
};

// Initialize Firebase
const FirebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(FirebaseApp)




  export {FirebaseApp, auth}