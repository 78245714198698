// Styles
import { styles } from './styles';
import { Typography, Card, CardContent} from '@mui/material';


const ProductView = ({data, onClick, isSelected}) => {
    const {thumbUrl, name, brand, size, variation } = data;
    const classes = styles();
    
    // const handleClick= (id) => {
    //     // setHighlightProduct(id);
    //     // addSingleProductToState(product);
    //     // mergeDescriptionToProduct();
    //    // console.log(id)
    // }
    
    return (
        
        <Card 
            className={isSelected ? classes.rootPink : classes.root} 
            onClick={onClick}
        >
            <img
                className={classes.detailCover}
                src={thumbUrl ? thumbUrl : 'No Image'}
                alt={name}
            />
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography variant='subtitle1' className={classes.brand}>
                        {brand}
                    </Typography>
                    <Typography variant='subtitle1' className={classes.name}>
                        {name}
                    </Typography>
                    <Typography variant='subtitle1' className={classes.variation}>
                        {variation}
                    </Typography>
                    <Typography variant='subtitle1'>
                        <b>{size}</b>
                    </Typography>
                </CardContent>
            </div>
        </Card>
       
    )
}

export default ProductView;