import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Styles
import { Styles } from './styles';
import { Button, TextField, Container } from '@mui/material';

// Assets
import logo_image from '../../assets/logo_image.png'
import logo from '../../assets/logo.png'
import {getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { FirebaseApp } from "../../firebaseConfig";




const SignIn = () => {
    const [creds, setCreds] = useState({
        email: '', 
        password: ''
    });
  
    const navigate = useNavigate();
    const classes = Styles();

    const handleChange = (evt) => {
        setCreds({
            ...creds,
            [evt.target.name]: evt.target.value
        });
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        const auth = getAuth(FirebaseApp)
        setPersistence(auth, browserLocalPersistence)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return  signInWithEmailAndPassword(auth, creds.email, creds.password).then(() => {
            navigate('/review'); //go to 'review' page for now
            }).catch((err)=>{
                console.log(err.code)
               alert("Error signing in with email")
            })
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          alert("%s %s", errorCode, errorMessage)
        });

       
        
    };

    return (
        <Container className={classes.container} maxWidth='sm'>
            <div className={classes.titleStyles}>
                <img 
                    src={logo_image}
                    className={classes.image}
                    alt='glowninme logo'
                />
                <img
                    src={logo}
                    className={classes.image}
                    alt='glowninme title'
                />
            </div>
            <form className={classes.form} noValidate>
                <TextField
                    variant='standard'
                    margin='normal'
                    required
                    fullWidth
                    id='email'
                    label='Email Address'
                    name='email'
                    autoComplete='email'
                    onChange={handleChange}
                    autoFocus
                />
                <TextField
                    variant='standard'
                    margin='normal'
                    required
                    fullWidth
                    name='password'
                    label='Password'
                    type='password'
                    id='password'
                    onChange={handleChange}
                    autoComplete='current-password'
                />
                <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='secondary'
                    className={classes.submitButton}
                    onClick={handleSubmit}
                >
                    Sign In
                </Button>
            </form>
        </Container>
    );
};

export default SignIn;
