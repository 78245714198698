// Components


// Styles
import { styles } from './styles';
import {Avatar, TextField, Grid, Autocomplete} from '@mui/material';
import React, { useContext, useEffect, useRef } from 'react';
import ProductContext from '../../pages/reviewProduct/productContext';
import ImageUploading from "react-images-uploading";
import {Box} from '@mui/material';

import FirestoreHelper from '../../services/firestore/index.ts';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';


const ProductEntryForm = ({editable, allowImageUpload})=> {


    const context = useContext(ProductContext)

    const data = context.productData

    const [image, setImage] = React.useState(null)
    const [currentProductID, setCurrentProductID] = React.useState(null)
    const [numUsers, setNumUsers] = React.useState(null)

    const toast = useRef(null);
    const classes = styles();

    const [categories, setCategories] = React.useState([])
    // const [userProfile, setUserProfile] = React.useState(null)
    var userProfile = React.useRef(null)
       
    let brandInput, nameInput, variationInput, sizeInput, imageUrlInput;

    const setImageData = context.setImageData
    
    useEffect(()=>{
       
        if (data != null){
            if (currentProductID !== data.id){
                FirestoreHelper.getUserProfile(data.fromUser)
                .then( userData => {
                    // setUserProfile(userData)
                    userProfile.current = userData
                })

                if (categories.length === 0){
                    getCategories()
                }  
                
                setImage(null)
                setImageData(null)
                brandInput.value = data.brand
                nameInput.value = data.name
                variationInput.value = data.variation
                sizeInput.value = data.size
                imageUrlInput.value = data.imgurl ? data.imgurl : ""

                FirestoreHelper.getNumberUsers(data.id)
                .then(res => {
                    setNumUsers(res)
                    context.setNumUsers(res)
                   
                })
            }
           
            setCurrentProductID(data.id)
        }
        else {
            setImage(null)
            brandInput.value = ""
            nameInput.value = ""
            variationInput.value = ""
            sizeInput.value = ""
            imageUrlInput.value =  ""
            let dom = document.getElementById("imageDimension-field")
            dom.textContent = ""
        }
    }, [data, currentProductID])
  
  
    const getCategories = async () => {

        if (!editable) {return}
    
        FirestoreHelper.listAllCategory()
        .then(result => {
            let array = result.map((item) => {
                return item
            })
           
            setCategories(array)
        })
        .catch(err => {
            toast.current.show({severity:'error', summary: 'Operation encountered error', detail: "Failed to retrieve product category data", life: 3000});
        })

    }

  

    const onImageChange =(imageList, addUpdateIndex) => {
        
        if (imageList.length > 0){

            const fileSize = Math.round(imageList[0].file.size / 1000)
            const imageData = imageList[0].data
        
            if (fileSize < 1500){
                // FirestoreHelper.uploadFile(data.fromUser, imageData)
                setImage(imageData)
                setImageData(imageData)
            
            }
            else {
                toast.current.show({severity:'error', summary: 'Image file is too large', detail: "Please upload an image file that's less than 1mb in size", life: 3000});
            }
        }
        else {
           
            setImage(null)
            setImageData(null)

        }
        
       
    };

    const img = (<Zoom><img alt='' ref={(self) => {
        // onLoad replacement for SSR
        if (!self) { return; }
    
        const setDimension = () => {
            let dom = document.getElementById("imageDimension-field")
            dom.textContent = `${self.naturalWidth} x ${self.naturalHeight}`
        }
        const updateFunc = () => {
            setDimension()
        };
        self.onload = updateFunc;
        if (self.complete) {
            setDimension()
        }
      }} className={classes.imagePreview} src={image ? image : (data ? data.imgurl : "")} /></Zoom>)
    

    return (
     <>
        <Toast ref={toast} position="bottom-right"/>
            <Grid container spacing={0} style={{textAlign:'center', width:"100%"}}  
            align="center"
            justify="center"
            direction="column">   
                <Grid>
                    {data ?  
                    (<div>
                {userProfile.current 
                ? <div>
                        <Box sx={{display:'flex', height:40 }}>
                            <Avatar alt="" src={`https://storage.googleapis.com/beaudiary-ff001.appspot.com/images/${data.fromUser}/profileImage.jpg`} sx={{float:'left', top: 0}}/>
                            <p style={{position:'relative', textAlign:'left', marginLeft:'1em', top: -10}}>{data.fromUser === "glowinme" ? "glowinme" : userProfile.current.displayName}</p>
                        </Box>
                    </div> 
                :  <p style={{textAlign:'left', marginLeft: 50}}>{data.fromUser}</p>}
                    <p style={{position:'relative', textAlign:'left',marginLeft: 0}}>objectID: {data.id}</p>
                    {<p style={{position:'relative', textAlign:'left',marginLeft: 0, marginTop: -10}}>Number of Users: {numUsers ? numUsers : 0}</p>}
                    </div>
                    ) 
                : <><div style={{height:'125px'}} /></>}
        
                </Grid>
        
                <Grid className={classes.imagePreviewContainer}>
                {(image || data)? img : <Box sx={{width:"400px", backgroundColor: "lightGray", height:"400px"}}></Box>}
                </Grid>
                    <Box><div id='imageDimension-field' style={{height:20, marginBottom: 10}} /></Box>
                    {allowImageUpload ? <Box>
                        <ImageUploading
                            onChange={onImageChange}
                            maxNumber={1}
                            dataURLKey="data"
                        >
                            {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                            }) => (
                            <div style={{display:'flex'}}>
                                {image ?  
                                    <Button className={classes.button} label={"Remove"}
                                    style={{margin:'0 auto'}} icon='pi pi-trash'
                                    onClick={onImageRemove}/> 
                                    :
                                    <Button className={classes.button} label={"Upload image"}
                                    style={{margin:'0 auto'}}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                    />
                                }
                            </div>)}
                        </ImageUploading>
                    </Box> : <></>}
                <Grid>
                    <TextField
                    variant='filled'
                    margin='normal'
                    fullWidth
                    id='imgUrl'
                    rows={3}
                    name='imgUrl'
                    label="Image URL"
                    multiline={true}
                    InputLabelProps={{shrink:true}}
                    InputProps={{
                        readOnly: true,
                        }}
                    sx={(typeof image == String) && image ? {display:'block'} : {display:'none'}}    
                    />
                    
                    <TextField
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        id='imgUrl'
                        rows={3}
                        name='imgUrl'
                        label="Image URL"
                        inputRef={node => {
                            imageUrlInput = node;
                            }}
                        multiline={true}
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            readOnly: !editable,
                            }}
                            
                        onChange={(e) => {
                            if (e.nativeEvent.inputType === 'insertFromPaste'){
                                const url = e.target.value
                                if (url && url.length > 0 ){
                                    setImageData(url)
                                    setImage(url)
                                }
                            }
                        }}
                        sx={image && (typeof image == String)? {display:'none'} : {display:'block'}}    
                    />
                    
                </Grid>
                <Grid item>
            <div className={classes.inputDiv}>
                
                <TextField
                    variant='standard'
                    margin='normal'
                    fullWidth
                    id='brand_name_input'
                    name='brand_name_input'
                    label={'Brand name'}
                    required={true}
                    inputRef={node => {
                        brandInput = node;
                        }}
                    InputProps={{
                        readOnly: !editable,
                        }}
                    InputLabelProps={{ shrink: true }}
                    onChange = { event => {
                        let value = event.target.value;
                        context.changeBrand(value)}}
                />
                </div>
                <div className={classes.inputDiv}>
                <TextField
                    variant='standard'
                    margin='normal'
                    fullWidth
                    id='disabled'
                    name='disabled'
                    label='Product name'
                    required={true}
                    inputRef={node => {
                        nameInput = node;
                        }}
                    InputProps={{
                        readOnly: !editable,
                        }}
                    InputLabelProps={{ shrink: true }}
                    onChange = { event => {
                        let value = event.target.value;
                        context.changeName(value)}}
                />
                </div>
                <div className={classes.inputDiv}>
                <TextField
                    // error={colourNameError.status}
                    // helperText={colourNameError.helperText}
                    // disabled={areRequiredFieldsFilledIn}
                    variant='standard'
                    margin='normal'
                    fullWidth
                    id='colourName'
                    name='colourName'
                    label='Variation'
                    inputRef={node => {
                        variationInput = node;
                        }}
                    InputProps={{
                        readOnly: !editable,
                        }}
                    InputLabelProps={{ shrink: true }}
                    onChange = { event => {
                        let value = event.target.value;
                        context.changeVariation(value)}}
                />
            </div>
            <Grid container>
                <Grid item xs={2}>
                <div className={classes.inputDiv}>
                        <TextField
                            // error={sizeError.status}
                            // helperText={sizeError.helperText}
                            // disabled={areRequiredFieldsFilledIn}
                            variant='standard'
                            margin='normal'
                            fullWidth
                            id='size'
                            name='size'
                            label='Size'
                            inputRef={node => {
                                sizeInput = node;
                                }}
                            InputProps={{
                                readOnly: !editable,
                                }}
                                InputLabelProps={{ shrink: true }}
                                onChange = { event => {
                                let value = event.target.value;
                                context.changeSize(value)}}
                        />
                    </div>
                </Grid>
                <Grid item xs={4} sx={{marginLeft: 10}}>
                    {editable ? 
                        (<Autocomplete
                            sx={{marginTop: '20px'}}
                            options={categories || []}
                            autoHighlight
                            getOptionLabel={(option) => option}
                            id="category-selector"
                            value={data? data.category : null}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderInput={(params) => (
                            <TextField {...params} label="Category" variant="standard" required={true}/>
                            )}
                            onChange={(_, value)=> {
                                context.changeCategory(value)
                            }}
                        />) :  
                        (<TextField sx={{marginTop: '20px'}} label="Category" variant="standard" required={true} defaultValue={data? data.category : ""}/>)
                    }
                </Grid>
            </Grid>
            {/* <div className={classes.inputDiv}>
                <TextField
                    // error={productPageUrlError.status}
                    // helperText={productPageUrlError.helperText}
                    // disabled={areRequiredFieldsFilledIn}
                    variant='standard'
                    margin='normal'
                    required
                    rows={4}
                    fullWidth
                    id='productPageUrl'
                    name='productPageUrl'
                    label='Product page url'
                    InputProps={{
                        readOnly: !editable,
                        }}
                        InputLabelProps={{ shrink: true }}
                    // onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                />
            </div> */}
    
            {/* <div className={classes.inputDiv}>
                <TextField
                    // error={descriptionError.status}
                    // helperText={descriptionError.helperText}
                    // disabled={checkBoxState}
                    placeholder='Describe the Product'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='description'
                    name='description'
                    multiline={true}
                    rows={4}
                    rowsmax={7}
                    label='Description'
                    InputProps={{
                        readOnly: !editable,
                        }}
                        InputLabelProps={{ shrink: true }}
                    // onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                />
    
                <TextField
                    // error={ingredientsError.status}
                    // helperText={ingredientsError.helperText}
                    // disabled={checkBoxState}
                    placeholder='List Ingredients'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='ingredients'
                    name='ingredients'
                    multiline={true}
                    rows={4}
                    rowsmax={7}
                    label='Ingredients'
                    InputProps={{
                        readOnly: !editable,
                        }}
                        InputLabelProps={{ shrink: true }}
                    // onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                />
                
                <TextField
                    // error={usageError.status}
                    // helperText={usageError.helperText}
                    // disabled={checkBoxState}
                    placeholder='Describe product usage'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='usage'
                    name='usage'
                    multiline={true}
                    rows={4}
                    rowsmax={7}
                    label='Usage (Optional)'
                    InputProps={{
                        readOnly: !editable,
                        }}
                        InputLabelProps={{ shrink: true }}
                    // onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                />
            </div> */}
                </Grid>
        </Grid>
        
    </>
    
       
    );
    
}

export default ProductEntryForm;