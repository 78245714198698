

import { useState, useContext } from 'react';
// Actions
// import { openDrawer, closeDrawer } from './redux/actions';

// Styles
import { styles } from './styles';
import { useTheme } from '@mui/styles';
import clsx from 'clsx';
import {useNavigate} from "react-router-dom";

import {Drawer, CssBaseline, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SignOut from '../signOut';
import AuthContext from '../authContext';

const Nav = () => {
    const classes = styles();
    const theme = useTheme();
    const navigate = useNavigate();

    const [drawerStatus, setDrawerStatus] = useState(false);
    const authContext = useContext(AuthContext)

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position='fixed'
                color='secondary'
                className={clsx(classes.appBar, {
                [classes.appBarShift]: drawerStatus
                })}
            >
                <Toolbar>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        onClick={() => {
                            setDrawerStatus(!drawerStatus)
                            }}
                        edge='start'
                        className={clsx(classes.menuButton, drawerStatus && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant='persistent'
                anchor='left'
                open={drawerStatus}
            >
                <div className={classes.drawerHeader}>
                    <Typography color='secondary' className={classes.title} variant='h6' noWrap>
                        Glowinme
                    </Typography>
                    <IconButton onClick={() => {setDrawerStatus(false)}}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <List>
                <ListItemButton key={1} onClick={() => navigate('/add-product')} >
                    <ListItemIcon>
                        <PlayArrowIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Add Product'} />
                </ListItemButton>
                {authContext.isAdmin ? 
                <ListItemButton key={2} onClick={() => navigate('/review')}>
                    <ListItemIcon>
                        <PlayArrowIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Review Product'} />
                </ListItemButton>
                : <></>}
                {/* <ListItem key={3} onClick={() => navigate('/elastic')}>
                    <ListItemIcon>
                        <PlayArrowIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Browse Elastic'} />
                </ListItem> */}
                <SignOut />
            </List>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: drawerStatus
                })}
            >
                <div className={classes.drawerHeader} /> 
            </main>
        </div>
    );
};



export default Nav;