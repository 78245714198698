import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";


// Components
import ProductView from './ProductView';





const ProductList = ({result, fetchData, hasMorePage, onItemSelected, maxHeight, selectedIndex, noMoreItemMessage}) => {
    
    const defaultMessage = noMoreItemMessage ? noMoreItemMessage : "Nothing to show" 

    const productListData = result
    
    return (
        <div id="scrollableDiv" style={{overflow: 'auto', width: '100%'}}>
            <InfiniteScroll
                dataLength={productListData.length}
                next={fetchData}
                hasMore={hasMorePage}
                loader={<h4>Loading...</h4>}
                height={maxHeight ? maxHeight: '700px'}
                scrollableTarget="scrollableDiv"
                endMessage={
                    <p><b>{defaultMessage}</b></p>
                }
                >
                {productListData.map((item, index) => (
                // <div key={index}>
                    <ProductView key={index}  data={item} onClick={() => {
                        
                        if (selectedIndex === index){
                    
                            if (onItemSelected !== undefined){
                                onItemSelected(index, null)
                            }
                        }
                        else {
                            
                            if (onItemSelected !== undefined){
                                onItemSelected(index, item)
                            }
                        }
                        
                        // this.handleClick(item.id)
                    }} isSelected={index === selectedIndex}/>
                // </div>
                ))}
                
            </InfiniteScroll>
    </div>
    )
    
}

// const mapStateToProps = state => {
//     return {
//         productList: state.productListReducer,
//         lastKey: state.productListReducer.lastKey,
//         productHighlighted: state.productListReducer.productHighlighted

//     }
// }

// const mapDispatchToProps = { 
//     setHighlightProduct,
//     addSingleProductToState
// };

export default ProductList;