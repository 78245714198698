import { makeStyles } from '@mui/styles';
import { fontWeight } from '@mui/system';

export const styles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    rootPink: {
      display: 'flex',
      backgroundColor: 'pink !important'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 100,
      height: 100,
      margin: theme.spacing(2),
     
    },
    detailCover: {
      width: 100,
      height: 100,
      margin: theme.spacing(1),
      display: 'block'
    },
    brand:{
      fontSize: '18px !important',
      fontWeight: "700 !important",
      lineHeight: "1.2em !important"
    },
    name:{
      fontSize: '18px !important',
      fontWeight: "400 !important",
      lineHeight: "1.2em !important",
      color: "#4d4848",
      marginTop: "10px",
    },
    variation: {
      fontSize: '16px !important',
      fontWeight: "200 !important",
      lineHeight: "1em !important",
      marginTop: "10px",
    }
}));