
import { auth } from "../../firebaseConfig";
import {Product} from '../product';
import { hostAddress } from '../../serverAddress';
import { async } from "@firebase/util";


class FirebaseHelper {


    constructor(){
        throw new Error("FirestoreHelper should not be instantiated.")
    }


    static verifyToken = async (): Promise<any> => {
        return new Promise ((resolve, reject) => {
                const unsub = auth.onAuthStateChanged(async (user) => {
            
                unsub()

                if (user == null){
                    reject([new Error("User is not found"), null])
                }
                else {
                    const token = await user.getIdToken(true)
                    resolve(token)
                }
            })
        })
    }

    static sendRequest = (queryType: string, param: string | null, data: any = null): Promise<any> => {

        return new Promise (async (resolve, reject) => {
        
            let token = await this.verifyToken()
            var url = ""

            if (param){
                url = `${hostAddress}/${queryType}?${param}`
            }
            else {
                url = `${hostAddress}/${queryType}`
            }
            
            const options = {
                method: 'post',
                headers: new Headers({
                    "content-type": "application/json",
                }),
                body:JSON.stringify({"token":token, "data": data})
            };
            
         
            const res = await fetch(url, options).catch(err=>{
                reject(err)
            })
        
            if (res != null && res.ok) {
                try {
                    let json = await res.json()
              
                    resolve(json)
                }catch(err){
                    reject(err)
                }
            }
     
        })
    
    }


    static listWaitingApproval = async (cursor: String, pageSize: Number) : Promise<any> => {
    
        var param = `pageSize=${pageSize}`
                
        if (cursor != null){
            param = `cursor=${cursor}&pageSize=${pageSize}`
        }

        return this.sendRequest("listItems", param)
           

    }

    static listAllCategory = () : Promise<void> => {
    
        return this.sendRequest("allCategory", null)

    }

    static findDuplicates = (brand: string, name: string, variation: string) : Promise<any> => {
        let param = `brand=${encodeURIComponent(brand)}&name=${encodeURIComponent(name)}&variation=${encodeURIComponent(variation)}`
    
        return this.sendRequest("findDuplicates", param)

    }



    static updateProduct = (data: Product) : Promise<any> => {

        let json = JSON.stringify(data)

        return this.sendRequest("updateProduct", null, json)
    }

    static approveProduct = (data: Product) : Promise<any> => {
        
        let json = JSON.stringify(data)
       
        return this.sendRequest("approveProduct", null, json)
    }

    static deleteProduct = (id: string) : Promise<any> => {
        
        let param = `id=${encodeURIComponent(id)}`
       
        return this.sendRequest("deleteProduct", param, null)
    }
    

    static rejectProduct = (id: string, reason: string) : Promise<any> => {
        //queryType=rejectProduct`
        let param = `id=${encodeURIComponent(id)}`
    
        return this.sendRequest("rejectProduct", param, reason)

    }

    static getUserProfile = (userID: string) : Promise<any> => {

        let param = `userID=${encodeURIComponent(userID)}`

        return this.sendRequest("userProfile", param, null)

    }

    static getNumberUsers = (id: string) : Promise<any> => {

        let param = `id=${encodeURIComponent(id)}`

        return this.sendRequest("numUsers", param, null)

    }

    static uploadFile = (userID: string, imageData: any) : Promise<any> => {

        let param = `ownerID=${encodeURIComponent(userID)}`

        return new Promise (async (resolve, reject) => {
        
            let token = await this.verifyToken()

            let url = `${hostAddress}/fileUpload?${param}`
            

            let formData = new FormData()
            formData.append('imageData', imageData)
            formData.append('token', token)

            const options = {
                method: 'post',
                body:formData
            };
            
         
            const res = await fetch(url, options).catch(err=>{
                reject(err)
            })
        
            if (res != null && res.ok) {
                let json = await res.json()
                resolve(json)
            }
            
        })
    }

    static checkIsAdmin = (userID: string) : Promise<any> => {

        let param = `userID=${encodeURIComponent(userID)}`

        return this.sendRequest("user", param, null)

    }


}


// export {listWaitingApproval, listAllCategory, findDuplicates}
export default FirebaseHelper;


