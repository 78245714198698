
import { auth } from "../../firebaseConfig";
import {Product} from '../product';
import { hostAddress } from '../../serverAddress';




function queryElastic(keyword: string, currentPage: Number, approved: Boolean, callback: Function) {
    
   const unsub = auth.onAuthStateChanged((user) =>{
        unsub()
        if (user == null){
            callback([])
        }
        else {
            user.getIdToken(true)
            .then((token) => {
                
                const url = `${hostAddress}/search?keyword=${encodeURIComponent(keyword)}&current=${currentPage}&approved=${approved}&pageSize=20`
                
                const options = {
                    method: 'post',
                    headers: new Headers({'content-type': 'application/json'}),
                    body:JSON.stringify({"token":token})
                };
                fetch(url, options)
                .then(res => res.json())
                .then(
                (result) => {callback(keyword, result)},
                (error) => {
                    console.error(error)
                    callback(keyword, null)
                })
                .catch(err => {
                    console.error(err)
                    callback(keyword, null)
                })
            })
        }
    })

}


function translateItemData(item): Product{

    var size: string = ""
    if ('size' in item){
        size = item.size.raw
    }
    var brand: string = ""
    if ('brand' in item){
        brand = item.brand.raw
    }
    var category: string = ""
    if ('category' in item){
        category = item.category.raw
    }
    var thumburl: string = ""
    if (!('thumburl' in item)){
        console.log(item.id)
    }
    else {
        thumburl = item.thumburl.raw
    }

    var imageUrl = item.imgurl.raw
    if (!imageUrl.startsWith("http")){
      
        imageUrl = 'https://' + imageUrl
    }

    let approval: boolean = false
    if (item.approved.raw === "true"){
        approval = true
    }

    return {id: item.id.raw, 
        name: item.name.raw, 
        brand: brand, 
        variation: item.variation.raw,
        thumbUrl: thumburl,
        category: category,
        size: size,
        imgurl: imageUrl,
        fromUser: item.fromuser.raw,
        approved: approval
    }


}

export {queryElastic, translateItemData}