import Grid from '@mui/material/Grid';
import {TextField, FormControl, Radio, RadioGroup, FormLabel, FormControlLabel} from '@mui/material';

import React, {useEffect, useContext, forwardRef, useImperativeHandle} from 'react';


import ProductContext from '../reviewProduct/productContext';

import { queryElastic, translateItemData } from "../../services/elastic/index.ts";
import ProductList from '../../components/productList';
import Cookies from 'universal-cookie'
import { Button } from 'primereact/button';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import './style.css'


const ElasticSearchPanel = forwardRef(({searchKeyword}, ref) => {
    const cookie = new Cookies()
   
    var keyword = React.useRef("")
    var currentPage = React.useRef(1)

    const context = useContext(ProductContext)

    const [hasMorePage, setHasMorePage] = React.useState(false);
  
    const [result, setResult] = React.useState([])
    const [productListSelectedIndex, setProductListSelectedIndex] = React.useState(null)

    const [isSearching, setIsSearching] = React.useState(false)

    var onlyApproved = true
        
    if (cookie.get('choice') === 'Unapproved'){
        onlyApproved = false
    }

    const [approvedOnly, setApprovedOnly] = React.useState(onlyApproved)

    const keyDownHandler = (event) =>{
        if (event.key === 'Enter') {
            
            currentPage.current = 1
           fetchData()
        }
    }

    useImperativeHandle(ref, () => ({

        deleteItem(id) {

            let idList = result.map(e => {
                return e.id
            })
    
            let index = idList.indexOf(id)
    
            if (index >= 0){
                result.splice(index, 1)
                setResult(result)
                setProductListSelectedIndex(null)
            }
    
    
        }
    
      }))

   



    document.addEventListener('keydown', keyDownHandler);

    let textInput;
   

    const fetchData = () =>{
        if (textInput == null || textInput.value.length === 0) {
            return
        }
        setIsSearching(true)
        queryElastic(textInput.value, currentPage.current, approvedOnly, (requestedKeyword, json)=>{
            setIsSearching(false)
            if (json.length === 0){
                setHasMorePage(false)
                currentPage.current = 1
                return
            }

            let array = json.results.map((item) => {
               
               return translateItemData(item)
             })
        
            
             if (array.length === 0 && currentPage.current === 1){
                setHasMorePage(false)
                setResult([])
                 
             }
             else {
                if (requestedKeyword === keyword.current){
                   
                    if (currentPage.current === 1){
                        setResult(array)
                    }
                    else {
                        setResult(result.concat(array))
                    } 
                }
                else {
                    keyword.current = requestedKeyword
                    setResult(array)
                   
                }
                currentPage.current += 1
                setHasMorePage(true)
             }

        })

       
    };

    const handleRadioChange = (event) => {

        let value = event.target.value
 
        if (value === 'Approved'){
             setApprovedOnly(true)
             cookie.set('choice', 'Approved')
            
        }
        else {
             setApprovedOnly(false)
             cookie.set('choice', 'Unapproved')
        }
        setResult([])
        setHasMorePage(false)
        currentPage.current = 1
        setProductListSelectedIndex(null)
     };


    useEffect(()=>{
       

        if (searchKeyword && searchKeyword.length > 0){
            
            if (searchKeyword !== textInput.value){
                setResult([])
                setProductListSelectedIndex(null)
                currentPage.current = 1
                textInput.value = searchKeyword
               
            }
            
        }
        else if (keyword.current && textInput){
            textInput.value = keyword.current
        }

        fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKeyword, approvedOnly])

    const handleSearchButton = () => {
        currentPage.current = 1
        fetchData()
    }

   

    return (
        <>
          <Grid container style={{textAlign:'left', width:"100%", paddingLeft:'10px'}}  
            align="center"
            justify="center"
            direction="column">   
                
            <Grid sx={{display:'flex'}}>
                <TextField inputRef={node => {textInput = node}} sx={{width:"100%"}} variant="standard" placeholder={'product name'} label={"Search products"}  InputLabelProps={{ shrink: true }}/>
                <Button style={{backgroundColor: "#ed1e7a", border: "#ed1e7a", width:"50px", float: 'right', height:44}} icon="pi pi-search" onClick={() => {
                       handleSearchButton()
                    }} loading={isSearching} />
            </Grid>
            <Grid>
                <FormControl sx={{marginTop:2}}>
                <FormLabel >Search option</FormLabel>
                    <RadioGroup row
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={ cookie.get('choice') ? cookie.get('choice') : "Unapproved"}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="Approved" control={<Radio onChange={handleRadioChange}/>} label="Approved" />
                        <FormControlLabel value="Unapproved" control={<Radio onChange={handleRadioChange}/>} label="Unapproved" />
                    </RadioGroup>
                </FormControl>
                <ProductList result={result} fetchData={fetchData} hasMorePage={hasMorePage}
                onItemSelected={(index, item)=>{
                
                    if (productListSelectedIndex !== null && productListSelectedIndex === index){
                        setProductListSelectedIndex(null)
                        context.setProductData(null)
                    }
                    else {
                        setProductListSelectedIndex(index)
                        context.setProductData(item)
                    }
                }} maxHeight={window.innerHeight * 1} selectedIndex={( productListSelectedIndex !== null) ? productListSelectedIndex : null}/>
                    
            </Grid>
        </Grid>
        </>
    )
});

export default ElasticSearchPanel;

