import React from "react";

// set the defaults
const ProductContext = React.createContext({id: "", 
                name: "", 
                brand: "", 
                variation: "",
                thumbUrl: ""
            });
  
  export default ProductContext;