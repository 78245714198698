import { makeStyles } from '@mui/styles';

export const Styles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(20),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    titleStyles: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    image: {
        width: '50%',
        margin: theme.spacing(1)
    },
    initTitle: {
        fontWeight: 400,
        fontSize: 30,
        margin: '0 auto',
        display: 'block'
    },
    spinner : {
        marginLeft: 20
    }

}));