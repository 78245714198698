import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom'

// Pages & Layouts
import SignIn from '../components/signIn';
import AddProductPage from '../pages/addProduct';
import ReviewProduct from '../pages/reviewProduct';
// Styles
import { theme } from './styles';
import { auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';

import FirebaseHelper from '../services/firestore/index.ts';
import AuthContext from '../components/authContext';
import InitScreen from '../components/initScreen'

const PrivateRoute = () => {
 

  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = React.useState(null)

  const [isAdmin, setIsAdmin] = React.useState(null)

  let authContext = {isAdmin: isAdmin}

  useEffect(() => {
    let unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        setCurrentUser(user)
        if (window.location.pathname === "/"){
          navigate("/review")
        }

        FirebaseHelper.checkIsAdmin(user.uid)
          .then(role => {
              if (role && role === "admin"){
                  setIsAdmin(true)
                  
                  }
          })
      }
      else {
        navigate("/sign-in")
      }
      unsubscribe();
    })
    
  });
 
  return ( 
    <>
     {isAdmin !== null ? 
       <AuthContext.Provider value={authContext}>
        <Outlet/>
        </AuthContext.Provider> 
        : <InitScreen />}
    </>
  )
}

const App = () => {

  return (

    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path='/sign-in' element={ <SignIn />} />
          <Route path="/" element={<PrivateRoute/>} >
              <Route path="/add-product" element={<AddProductPage/>} />
              <Route path="/review" element={<ReviewProduct/>} /> 
              {/* <Route path="/elastic" element={<ElasticPage/>} /> */}
          </Route>
        </Routes>
      </Router>
   </ThemeProvider>


  );
}

export default App;
