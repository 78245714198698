import { makeStyles } from '@mui/styles';

export const styles = makeStyles((theme) => ({

    successIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    successMessage: {
        paddingLeft: theme.spacing(1),
    },
    inputDiv: {
        margin: theme.spacing(0.5),
    },
    algoliaTitle: {
        marginBottom: theme.spacing(2)
    },
    checkBoxDiv: {
        display: 'flex',
        flexDirection: 'row'
    },
    algoliaErrorMsgDiv: {
        marginTop: theme.spacing(-1.3)
    },
    algoliaErrorMsg: {
        color: '#FF0000',
        marginLeft: theme.spacing(1.3)
    },
  
    imagePreviewContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width:'100%',
        height:'100%',
    },
    imagePreview: {
        width: '400px',
        height: '400px',
        backgroundColor: 'lightgray',
        marginBottom: '20px'
    },
    button: {
        background: 'rgb(237, 30, 122)',
        border: 'rgb(237, 30, 122) !important',
        "&:hover": {
            background: 'white !important',
            color: 'rgb(237, 30, 122) !important'
        },
    },

}));