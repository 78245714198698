
// Styles
import { Styles } from './styles';
import { Container } from '@mui/material';

// Assets
import logo_image from '../../assets/logo_image.png'
import logo from '../../assets/logo.png'
import { ProgressSpinner } from 'primereact/progressspinner';




const InitScreen = () => {

    const classes = Styles();


    return (
        <Container className={classes.container} maxWidth='sm'>
            <div className={classes.titleStyles}>
                <img 
                    src={logo_image}
                    className={classes.image}
                    alt='glowninme logo'
                />
                <img
                    src={logo}
                    className={classes.image}
                    alt='glowninme title'
                />
                  <div className={classes.initTitle}>
                    <p>Initializing...</p>
                    <div className={classes.spinner}><ProgressSpinner />
                </div>
            </div>
            </div>
          
           
        </Container>
    );
};

export default InitScreen;
