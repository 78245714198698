

// import ProductReviewForm from '../../features/productReviewForm';
// import DatabaseReviewDisplay from '../../features/databaseReviewDisplay'

// Styles


import {Stack, Grid, Box} from '@mui/material';
import React, { useEffect, useRef, useCallback, useContext } from 'react';
import ProductEntryForm from '../../components/productEntryForm';
import ProductContext from './productContext';
import FirestoreHelper from '../../services/firestore/index.ts';
import ProductList from "../../components/productList";
import ElasticSearchPanel from '../elasticReview/elasticSearchPanel';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import './style.css'
import Nav from '../../components/nav';
import AuthContext from '../../components/authContext';

const ReviewProduct = () => {


    const authContext = useContext(AuthContext)

    return (
        <>
        <Nav/>
        { authContext.isAdmin ? <ReviewProductComponent/> : <p>Not Authorized</p> }
      
        </>
    )

}


const ReviewProductComponent = () => {
   

    const pageSize = 20

    const [productData, setProductData] = React.useState(null)

    const [productListSelectedIndex, setProductListSelectedIndex] = React.useState(null)

    const [dupListSelectedIndex, setDupListSelectedIndex] = React.useState(null)
    
    const [searchKeyword, setSearchKeyword] = React.useState("")
   
    const [productList, setProductList] = React.useState({currentPage: 0, hasMorePage: false, data: [], lastObjectID: null})

    const [duplicates, setDuplicates] = React.useState([])

    const [blocked, setBlocked] = React.useState(true)

    const [numUsers, setNumUsers] = React.useState(null)

    const toast = useRef(null);

    const menu = useRef(null);


    
    const productContext = {productData,
        setImageData: (imageData) => {
            productData.imageData = imageData
            setProductData(productData)
        },
        changeBrand: (newValue) => {
            productData.brand = newValue
            setProductData(productData)
        },
        changeName: (newValue) => {
            productData.name = newValue
            setProductData(productData)
        },
        changeVariation: (newValue) => {
            productData.variation = newValue
            setProductData(productData)
        },
        changeCategory: (newValue) => {
            productData.category = newValue
            setProductData(productData)
        },
        changeSize: (newValue) => {
            productData.size = newValue
            setProductData(productData)
        },
        setNumUsers,
        setProductData,
    }


    const fetchData = useCallback(async () => {

       FirestoreHelper.listWaitingApproval(productList.lastObjectID, pageSize)
       .then(result =>{
        let array = result.map((item) => {
                
            return {id: item.id, 
                name: item.name, 
                brand: item.brand, 
                variation: item.variation,
                thumbUrl: item.thumbUrl,
                imgurl: item.imgurl,
                category: item.category,
                size: item.size,
                fromUser:  item.fromUser,
                approved: item.approved
            }
        })
        
        if (array.length === 0){
            if (productList.currentPage === 0){
                setProductList({currentPage: 0, hasMorePage: false, data: [], lastObjectID: productList.lastObjectID})
            }
            else {
                setProductList({currentPage: productList.currentPage, hasMorePage: false, data: productList.data, lastObjectID: productList.lastObjectID})
            }
        }
        else {
            if (blocked){
                setBlocked(false)
            }
            setProductList({currentPage: productList.currentPage + 1, hasMorePage: true, 
                data: productList.data.concat(array), 
                lastObjectID: array[array.length-1].id
            })
        }
       })
       .catch(err =>{
           toast.current.show({severity:'error', summary: 'Operation encountered error', detail: "Failed to retrieve list of products", life: 3000});
       })

    });

    useEffect(()=>{

        if (productList.data.length === 0 ){ 
            fetchData()
        }
       
    }, [fetchData])

    

    const setSelectedItem = async (item) => {
       
        if (item != null){
            setBlocked(true)
            FirestoreHelper.findDuplicates(item.brand, item.name, item.variation)
            .then(result => {
                let ids = result.map(elem => {
                        return elem.id
                })
                let index = ids.indexOf(item.id)
                if (index !== -1){
               
                    result.splice(index, 1)
                }
                
                
                setDuplicates(result)
            })
            .catch(err =>{
                setDuplicates([])
                toast.current.show({severity:'error', summary: 'Failed', detail: "Error finding duplicates", life: 3000});
            })
            setBlocked(false)
            setSearchKeyword(`${item.brand} ${item.name} ${item.variation}`)
        }
        else {
            setDuplicates([])
            setProductListSelectedIndex(null)
            setSearchKeyword("")
            
        }
        setProductData(item)
       
        
    }

    const handleServerResponse = (productData, res, message) => {
       if (res === "done"){
            toast.current.show({severity:'success', summary: 'Operation completed successfully', detail: message, life: 3000});
             
            if (productListSelectedIndex >= 0) {

                if (duplicates.length === 0 || dupListSelectedIndex == null){

                    productList.data.splice(productListSelectedIndex, 1)

                    if (productListSelectedIndex < productList.data.length){
        
                        let nextProduct = productList.data[productListSelectedIndex]
        
                        setSelectedItem(nextProduct)
                    }

                    setProductList({currentPage: productList.currentPage, hasMorePage: productList.hasMorePage, 
                        data: productList.data, lastObjectID: productList.lastObjectID})
                }
                else if (duplicates.length > 0){

                    let ids = duplicates.map(elem => {
                        return elem.id
                    })
                    let index = ids.indexOf(productData.id)

                    duplicates.splice(index, 1)
                    if (index < duplicates.length){
                        setDupListSelectedIndex(index)
                    }
                    let item = duplicates[index]
                    setDuplicates(duplicates)
                    setProductData(item)
                }
            }
        }
        else {
            toast.current.show({severity:'error', summary: 'Error', detail: res.error, life: 3000});
        }
    }

    const confirm = (reason) => {
       
        if (productData != null){

            confirmDialog({
                message: 'Are you sure you want to reject the item?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                   
                    FirestoreHelper.rejectProduct(productData.id, reason)
                   .then((res)=>{
                        handleServerResponse(productData, res, "Item(s) rejected")
                   })
                   .catch(err => {
                    toast.current.show({severity:'error', summary: 'Failed', detail: "Error rejecting item", life: 3000});
                   })
                   
                },
            });
        }
    }

    const elastic = React.useRef(null)

    const deleteDialog = (moveToNext) => {
       
        if (productData != null){

            confirmDialog({
                message: 'Are you sure you want to delete the item?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                   
                    FirestoreHelper.deleteProduct(productData.id, "empty/error item")
                   .then((res)=>{
                        if (moveToNext){
                            handleServerResponse(productData, res)
                        }
                        else {
                            if (res === "done"){
                                elastic.current.deleteItem(productData.id)
                                toast.current.show({severity:'success', summary: 'Operation completed successfully', detail: "Item is deleted", life: 3000});
                            }
                        }
                   })
                   .catch(err => {
                    toast.current.show({severity:'error', summary: 'Failed', detail: "Error deleting item", life: 3000});
                   })
                   
                },
            });
        }
    }

    const items = [
        {
            label: 'Reject reason',
            items: [
                {
                    label: 'Duplicate',
                    icon: 'pi pi-times',
                    command: () => {
                        confirm('Duplicates')
                    }
                },
                {
                    label: 'Poor picture',
                    icon: 'pi pi-times',
                    command: () => {
                        confirm("Poor picture")
                    }
                },
                {
                    label: 'Garbage',
                    icon: 'pi pi-times',
                    command: (e) => {
                        confirm('Garbage')
                    }
                },
                {
                    label: 'Other',
                    icon: 'pi pi-times',
                    command: (e) => {
                        confirm('Other')
                    }
                },
            ]
        },
    ];



    return (
        <>
        <Toast ref={toast} position="bottom-right"/>
        <BlockUI blocked={blocked}>
        {blocked ? (<div style={{position: "fixed", top: "20%", left: "50%", transform: "translate(-50%, -50%)"}}>
            <ProgressSpinner />
        </div>) : <></>}
        <Grid container style={{marginLeft:'20px', marginTop:-60}} >
                <h1>Review Entry</h1>
        </Grid>
        <Grid container sx={{display:'flex', margin:"0 2em"}}>
            <Grid item xs={2.5} sm={2.5} md={2.5}>
              
                <ProductList result={productList.data} fetchData={fetchData} hasMorePage={productList.hasMorePage}
                onItemSelected={(index, item)=>{
                    setProductListSelectedIndex(index)
                    setDupListSelectedIndex(null)
                    setSelectedItem(item)
                    if (item){
                        setSearchKeyword(`${item.brand} ${item.name} ${item.variation}`)
                    }
                }} maxHeight={window.innerHeight * 1} selectedIndex={( productListSelectedIndex !== null) ? productListSelectedIndex : null}/>
               </Grid>
               <Grid item xs={2.5} sm={2.5} md={2.5}>
                <ProductList style={{position:"relative",marginTop: "0px",marginLeft:"300px"}} result={duplicates} fetchData={()=>{}} hasMorePage={false}
                onItemSelected={(index, item)=>{
                    if (dupListSelectedIndex == null || index !== dupListSelectedIndex){
                        setDupListSelectedIndex(index)
                    }
                    else {
                        setDupListSelectedIndex(null)
                    }
                    if (item){
                        setSearchKeyword(`${item.brand} ${item.name} ${item.variation}`)
                    }
                    setProductData(item)
                }} maxHeight={window.innerHeight * 1} selectedIndex={( dupListSelectedIndex !== null) ? dupListSelectedIndex : null} noMoreItemMessage={"No similar items found"}/>
                
            </Grid>
            <Grid item xs={4} sx={{marginLeft:"2em"}}>
                <ProductContext.Provider value={productContext}>
                    <ProductEntryForm editable={true} allowImageUpload={false}/>
                </ProductContext.Provider>
                <Box sx={{float:'right', position:'relative', top: -950}}>
                {(productData == null) ? <></> : 
            
                (<Stack id="button-group" spacing={2} >
                    <Menu model={items} popup ref={menu} />
                    <ConfirmDialog />
                    {productData.approved 
                    ? <> 
                    <Button label={"Update"} key="two" onClick={(e) => {
                        e.preventDefault()
                        FirestoreHelper.updateProduct(productData)
                        .then(res => {
                            if (res === "done"){
                                toast.current.show({severity:'success', summary: 'Update completed successfully', detail: "Detail of the product is updated", life: 3000});
                            }
                        })

                    }} icon="pi pi-bookmark" className='p-button-info' />
                    <Button label={"Reject"} key="one" onClick={(e) => menu.current.toggle(e)} icon="pi pi-times" className='p-button-warning' />
                    {numUsers === 0 ? 
                        <Button label={"Delete"} key="three" icon="pi pi-trash" className="p-button-danger" onClick={async (event)=>{
                            event.preventDefault()
                        
                            deleteDialog(false)
                        }}/> : <></>}
                    </>
                    : (<>
                        <Button label={"Reject"} key="one" onClick={(e) => menu.current.toggle(e)} icon="pi pi-times" className='p-button-warning' />
                        <Button label={"Accept"} key="two" icon="pi pi-check" className="p-button-success" onClick={async (event)=>{
                            event.preventDefault()
                        
                            FirestoreHelper.approveProduct(productData)
                            .then(res =>{
                                handleServerResponse(productData, res, "Item approved")
                            })
                        }}/> 
                        {numUsers === 0 ? 
                        <Button label={"Delete"} key="three" icon="pi pi-trash" className="p-button-danger" onClick={async (event)=>{
                            event.preventDefault()
                        
                            deleteDialog(true)
                        }}/> : <></>}
                        </>
                    )}
                </Stack>)}  
                </Box>
            </Grid>
            
            <Grid item xs={2.5} sm={2} md={2} sx={{marginLeft:"4em", float:'right'}}>
            <ProductContext.Provider value={productContext}>
                <ElasticSearchPanel searchKeyword={searchKeyword} ref={elastic}/> 
                </ProductContext.Provider>
            </Grid>
                
            
        </Grid>
        </BlockUI>
        </>
    )
};

export default ReviewProduct;