import { makeStyles } from '@mui/styles';

export const Styles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(20),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    titleStyles: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    image: {
        width: '50%',
        margin: theme.spacing(1)
    },
    form: {
        width: '100%', 
        marginTop: theme.spacing(1),
    },
    submitButton: {
        marginTop: theme.spacing(1),
    },
}));