import { useNavigate } from 'react-router-dom';


// Styles
import {ListItemButton, ListItemIcon, ListItemText} from '@mui/material';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import {getAuth, signOut } from 'firebase/auth';
import { FirebaseApp } from "../../firebaseConfig";


const SignOut = ({ clearForm }) => {
   
    const navigate = useNavigate();
    const auth = getAuth(FirebaseApp)
    
 
    const handleSignOut = (evt) => {
        evt.preventDefault();
        // clearForm();
        signOut(auth).then(() => {
            navigate("/")
        })
    };
    return (
        <ListItemButton onClick={handleSignOut} key={2}>
            <ListItemIcon>
                <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={'Sign Out'} />
        </ListItemButton>
    );
};


export default SignOut;