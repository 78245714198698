// Components
import ProductEntryForm from '../../components/productEntryForm';
// Styles
import {Grid, Container} from '@mui/material';
import Nav from '../../components/nav';


const AddProductPage = () => {
    return (
        <Container maxWidth='lg'>
            <Nav />
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <ProductEntryForm />
                </Grid>
                <Grid item xs={6}>
                    {/* <DatabaseDisplay /> */}
                </Grid>
            </Grid>
        </Container>
    );
};

export default AddProductPage;